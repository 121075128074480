import { snakeCase } from 'lodash/fp'

import { IconName } from 'packages/iconic'

/**********************************************************
 * UTILITY TYPES
 *********************************************************/
// enum values for the API `task.realtimeStatus` computed property
export enum TaskRealtimeStatus {
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  IN_PROGRESS_OVERDUE = 'in_progress_overdue',
  MISSED = 'missed',
  NOT_READY = 'not_ready',
  READY = 'ready',
  READY_OVERDUE = 'ready_overdue',
}

/**
 * The various values that can be used for `service_type` on a Task.
 * This is a direct mapping to the values the API uses.
 */
export enum TaskServiceType {
  adhoc = 'adhoc',
  b2b = 'b2b',
  lateCheckout = 'late_checkout',
  midStay = 'mid_stay',
  potentialB2B = 'potential_b2b',
  scheduled = 'scheduled',
  standard = 'standard',
}

export type TaskJobType = 'standard' | 'deep_clean' | 'inspection'

export const computedTaskJobTypeValues = ['post', 'deep', 'visit'] as const
export type ComputedTaskJobType = (typeof computedTaskJobTypeValues)[number]

export type InspectionFlag = {
  categoryId: string
  deletedAt: string
  id: string
  itemId: string
  notes: string
  taskId: string
  title?: string
}

/**********************************************************
 * TASK
 *********************************************************/
export type AllTaskAttributes = {
  completedAt: null | string
  createdAt: string
  dueDate: string
  earliestCleanStartTime: string
  effectiveDate: string
  hasCleanPhotos: boolean
  inspectionChecklist: InspectionChecklist | null
  inspectionCompletedAt: null | string
  inspectionFlags: InspectionFlag[] | null
  inspectionNotes: string | null
  isActive: boolean
  isNextStayOwner: boolean
  jobType: TaskJobType[]
  lastCleanCompletedAt: string | null
  notes: string | null
  predictedCleanTime: number
  realtimeStatus: TaskRealtimeStatus
  serviceType: TaskServiceType
  startedAt: null | string
  ticketAttachedCount: number
  timersSubmitted: boolean
}

export const AllTaskAttributeNames = [
  'createdAt',
  'completedAt',
  'dueDate',
  'earliestCleanStartTime',
  'effectiveDate',
  'hasCleanPhotos',
  'inspectionChecklist',
  'inspectionCompletedAt',
  'inspectionNotes',
  'isActive',
  'isNextStayOwner',
  'jobType',
  'lastCleanCompletedAt',
  'metadata',
  'notes',
  'predictedCleanTime',
  'realtimeStatus',
  'startedAt',
  'serviceType',
  'ticketAttachedCount',
  'timersSubmitted',
]

export const TaskApiFields = AllTaskAttributeNames.map<string>(snakeCase)

/**********************************************************
 * INSPECTION
 *********************************************************/
export type InspectionCategoryType =
  | 'backyard'
  | 'bathroom'
  | 'bedroom'
  | 'custom'
  | 'dc_garage'
  | 'dc_inventory'
  | 'dc_kitchen_appliances'
  | 'dc_kitchen_stove_oven'
  | 'dc_kitchen_surfaces'
  | 'dc_outside'
  | 'entrance'
  | 'general'
  | 'hv_general'
  | 'hv_kitchen'
  | 'hv_others'
  | 'hv_safety'
  | 'kitchen'
  | 'living_room'
  | 'washer_dryer'

export enum InspectionCategoryTitle {
  backyard = 'Backyard',
  bathroom = 'Bathroom',
  bedroom = 'Bedroom',
  custom = 'Custom',
  dc_garage = 'Garage',
  dc_inventory = 'Linens',
  dc_kitchen_appliances = 'Kitchen Appliances',
  dc_kitchen_stove_oven = 'Stove/Oven',
  dc_kitchen_surfaces = 'Kitchen Surfaces',
  dc_outside = 'Outside',
  entrance = 'Entrance',
  general = 'General',
  hv_general = 'General',
  hv_kitchen = 'Kitchen',
  hv_others = 'Others',
  hv_safety = 'Safety',
  kitchen = 'Kitchen',
  living_room = 'Living Room',
  washer_dryer = 'Washer/Dryer',
}

export type InspectionCategory = {
  categoryType: InspectionCategoryType
  id: string
  title: string
}

export type isCompleteType = 'checked' | 'unchecked' | 'flagged'

export type InspectionListItem = {
  id: string
  isComplete: isCompleteType
  title: string
}

export type InspectionList = {
  category: InspectionCategory
  items: InspectionListItem[]
}

export type InspectionChecklist = {
  checklists: InspectionList[]
}

/* eslint-disable @typescript-eslint/naming-convention */
export const categoryIconMap: Record<InspectionCategoryType, IconName> = {
  backyard: IconName.sun,
  bathroom: IconName.bath,
  bedroom: IconName.bed,
  custom: IconName.keys,
  dc_garage: IconName.garage,
  dc_inventory: IconName.linens,
  dc_kitchen_appliances: IconName.fridge,
  dc_kitchen_stove_oven: IconName.cooking,
  dc_kitchen_surfaces: IconName.cabinet,
  dc_outside: IconName.sun,
  entrance: IconName.doorClose,
  general: IconName.keys,
  hv_general: IconName.keys,
  hv_kitchen: IconName.cooking,
  hv_others: IconName.keys,
  hv_safety: IconName.alertTriangle,
  kitchen: IconName.grill,
  living_room: IconName.couch,
  washer_dryer: IconName.washer,
}
/* eslint-enable @typescript-eslint/naming-convention */

export type NormalizedTaskData = {
  task: Record<
    string,
    {
      attributes: {
        jobType: TaskJobType[]
      }
    }
  >
}
