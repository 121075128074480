export enum Features {
  CROSS_COVERAGE = 'cross_coverage',
  CUSTOM_INSPECTION_CHECKLIST = 'custom_inspection_checklist',
  DYNAMIC_ZONE_ID_HANDLING = 'enable_dynamic_zone_id_handling_for_websockets',
  FORCED_OWNER_COMMENT = 'forced-owner-comment',
  MAINTENANCE = 'fieldops-web-maintenance-mode',
  MAKING_BORROWED_HK_MANDATORY_FRONTEND = 'making_borrowed_hk_mandatory_frontend',
  NEW_TICKET_ATTACHMENTS_ENDPOINT = 'new_ticket_attachments_endpoint',
  TICKET_ATTACHMENTS = 'ticket_attachments',
  TIMER_PURGE_DEBOUNCE = 'timer_purge_debounce',
}
