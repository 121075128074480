import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { toast } from 'react-toastify'

import { equalOrSmaller } from 'packages/styles'

import { Alert, AlertType } from '../Alert'

// this is the default breakpoint used by react-toastify to determine when toasts should go into "mobile mode"
const TOAST_BREAKPOINT = 480

const St = {
  ToastAlert: styled(Alert)`
    margin: 0;

    ${equalOrSmaller(
      TOAST_BREAKPOINT,
      css`
        border-radius: 0; // this makes them stack nicely on small screens
        padding-bottom: 32px; // extra padding on mobile to account for iOS junk
      `,
    )};
  `,
}

export enum ToastTestIds {
  container = 'Toast__container',
}

export type ToastConfig = {
  autoClose?: number | false
  message: React.ReactNode
  position?: 'bottom-left' | 'top-right'
  toastType?: AlertType
}

type UseToast = {
  showToast: (config: ToastConfig) => void
}

export const useToast = (): UseToast => {
  const showToast = React.useCallback((config: ToastConfig) => {
    const {
      autoClose = 5000,
      message,
      position = 'bottom-right',
      toastType = 'success',
    } = config

    toast(
      <St.ToastAlert
        alertType={toastType}
        dataTestId={ToastTestIds.container}
        onClose={() => toast.dismiss()}
      >
        {message}
      </St.ToastAlert>,
      {
        autoClose,
        closeButton: false,
        position,
      },
    )
  }, [])

  return { showToast }
}
