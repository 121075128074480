import { useDecision } from '@optimizely/react-sdk'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get' // eslint-disable-line
import startCase from 'lodash/startCase'
import * as React from 'react'
import { usePrevious } from 'react-use'
import { v4 as uuid } from 'uuid'

import {
  ConfirmModalSlugsType,
  SentryErrorBoundary,
  useConfirmModal,
  useErrorContext,
  useToast,
} from 'packages/common'
import { SvgIcon, IconName } from 'packages/iconic'
import { Features } from 'packages/optimizely'
import { getId } from 'packages/utils/collectionHelpers'
import { unitFullAddressString } from 'packages/utils/hkStringHelpers'

import { ZoneContext } from 'app/hkhub/components/zone/ZonePage/ZonePage.context'
import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'
import { Unit } from 'app/hkhub/store/units'
import {
  UnitWithHousekeeperPreferences,
  UnitPreferenceKind,
  UnitPreference,
} from 'app/hkhub/store/units/units.types'

import AddPreferenceModal, {
  AddPreferenceModalConfig,
} from '../components/AddPeferenceModal/AddPreferenceModal'
import UnitPreferenceCell from '../components/UnitPreferenceCell/UnitPreferenceCell'
import {
  getHkIdsFromPreferences,
  getSlugForUnitPreferenceKind,
} from '../helpers/unitPreferenceHelpers'
import { UnitPreferencesReduxProps } from '../preferences.types'
import {
  HeaderWrapper,
  HeaderText,
  ContentWrapper,
  CancelEditIcon,
  tableHeader,
  UnitAddress,
  StyledDataTable,
} from './UnitPreferencePage.styles'

export type RemovePreferenceModalConfig = {
  alias: UnitPreferenceKind
  id: string
}

// slug definitions for the ConfirmModal, based on the preference type being removed
const confirmModalSlugMap: Record<UnitPreferenceKind, ConfirmModalSlugsType> = {
  [UnitPreferenceKind.mandatory]: {
    message: Slugs.removeMandatoryDescription,
    title: Slugs.removeMandatory,
  },
  [UnitPreferenceKind.suggested]: {
    message: Slugs.removeSuggestedDescription,
    title: Slugs.removeSuggested,
  },
  [UnitPreferenceKind.excluded]: {
    message: Slugs.removeExcludedDescription,
    title: Slugs.removeExcluded,
  },
}

export enum UnitPreferencesPageTestIds {
  cancelEdit = 'unitPreferencePage__cancelEdit',
}

/**
 * Type for using in state for declaring the params for a pending request/action
 * Any operations on this page need to go through a confirmation dialog first, so this
 *    data structure is used to both hold the pending data while this is happening, as well
 *    as abstract the request to we can ultimately use the same handler for multiple operations.
 */
export type RequestConfig = {
  reduxAction: (...args: any) => Promise<any> // eslint-disable-line @typescript-eslint/no-explicit-any
  toastMsg: string
}

export type UnitPreferencesPageProps = UnitPreferencesReduxProps

const UnitPreferencesPage: React.FC<UnitPreferencesPageProps> = ({
  createUnitPreference,
  fetchUnitsByZoneWithPreferences,
  getAgencyHks,
  getPrimaryHks,
  getBorrowedHks,
  getUnitsWithHousekeeperPreferences,
  removeUnitPreference,
}) => {
  const { t } = useI18n()
  const { zone } = React.useContext(ZoneContext)
  const { setBannerError } = useErrorContext()
  const { showToast } = useToast()

  const [activeUnit, setActiveUnit] =
    React.useState<UnitWithHousekeeperPreferences>(
      {} as UnitWithHousekeeperPreferences,
    )

  const [isLoading, setIsLoading] = React.useState(true)

  const [unitsWithPreferences, setUnitsWithPreferences] = React.useState<
    UnitWithHousekeeperPreferences[]
  >([])

  const [loadingUnitIds, setLoadingUnitIds] = React.useState<string[]>([])

  const [addPreferenceModalConfig, setAddPreferenceModalConfig] =
    React.useState<AddPreferenceModalConfig | null>(null)

  const [removePreferenceModalConfig, setRemovePreferenceModalConfig] =
    React.useState<RemovePreferenceModalConfig>(
      {} as RemovePreferenceModalConfig,
    )
  const prevRemoveModalConfig = usePrevious(removePreferenceModalConfig)

  const [requestConfig, setRequestConfig] = React.useState<RequestConfig>(
    {} as RequestConfig,
  )

  const [availableHks, setAvailableHks] = React.useState<Housekeeper[]>([])

  const [decision] = useDecision(Features.MAKING_BORROWED_HK_MANDATORY_FRONTEND)
  const isFeatureEnabled = React.useMemo(
    () => decision.enabled,
    [decision.enabled],
  )

  React.useEffect(() => {
    if (getId(activeUnit)) {
      // it is related to FF making-borrowed-hk-mandatory-ff
      let borrowedHKsFF: Housekeeper[] = []
      if (isFeatureEnabled) {
        borrowedHKsFF = getBorrowedHks()
      }

      const primaryHks = getPrimaryHks()
      const agencyHks = getAgencyHks()
      const allHks = [...primaryHks, ...agencyHks, ...borrowedHKsFF]

      const hksAlreadyInPref = getHkIdsFromPreferences(
        activeUnit.housekeeperPreferences,
      )

      const hksToIncludeForPreferenceAssignment = allHks.filter(
        hk => !hksAlreadyInPref.includes(getId(hk)),
      )

      setAvailableHks(hksToIncludeForPreferenceAssignment)
    }
  }, [
    activeUnit,
    getAgencyHks,
    getPrimaryHks,
    getBorrowedHks,
    isFeatureEnabled,
  ])

  React.useEffect(() => {
    if (zone) {
      const fetchUnits = async () => {
        await fetchUnitsByZoneWithPreferences(zone.id)
        setIsLoading(false)
      }

      fetchUnits()
    }
  }, [fetchUnitsByZoneWithPreferences, zone])

  React.useEffect(() => {
    if (!isLoading) {
      const fetchedUnitsWithPreferences = getUnitsWithHousekeeperPreferences()
      if (fetchedUnitsWithPreferences.length) {
        setUnitsWithPreferences(fetchedUnitsWithPreferences)
      }
    }
  }, [getUnitsWithHousekeeperPreferences, isLoading])

  const openRemovePreferenceModal = React.useCallback(
    (preference: UnitPreference, unitId: string) => {
      const { id, alias } = preference

      setRequestConfig({
        reduxAction: () => {
          return removeUnitPreference(id, unitId)
        },
        toastMsg: capitalize(
          `${t(getSlugForUnitPreferenceKind(alias))} ${t(
            Slugs.housekeepersUpdated,
          )}`,
        ),
      })

      setRemovePreferenceModalConfig({ alias, id: uuid() })
    },
    [removeUnitPreference, t],
  )

  const openAddPreferenceModal = React.useCallback(
    (unit: UnitWithHousekeeperPreferences, alias: UnitPreferenceKind) => {
      setRequestConfig({
        reduxAction: (housekeeper, reason) => {
          return createUnitPreference({
            alias,
            housekeeperId: housekeeper.id,
            reason,
            unitId: unit.id,
          })
        },
        toastMsg: capitalize(
          `${t(getSlugForUnitPreferenceKind(alias))} ${t(
            Slugs.housekeepersUpdated,
          )}`,
        ),
      })

      setAddPreferenceModalConfig({ alias, unitCode: unit.unitCode })
    },
    [createUnitPreference, t],
  )

  const handleOnConfirm = React.useCallback(
    async (...args) => {
      const { toastMsg, reduxAction } = requestConfig

      const unitId = get(activeUnit, 'id', '')

      try {
        setLoadingUnitIds([unitId])
        await reduxAction(...args)
        showToast({ message: toastMsg })
      } catch (err) {
        setBannerError({
          message: t(Slugs.errorTechSupport),
        })
      } finally {
        setLoadingUnitIds([])
      }
    },
    [activeUnit, requestConfig, setBannerError, showToast, t],
  )

  const { showModal: showConfirmModal } = useConfirmModal({
    onConfirm: handleOnConfirm,
    slugs: confirmModalSlugMap[removePreferenceModalConfig?.alias],
  })

  // effect handler for showing a confirmation modal anytime the config is updated
  React.useEffect(() => {
    const config = removePreferenceModalConfig
    const isNewValidRequestConfig =
      config?.id && config.id !== prevRemoveModalConfig?.id

    if (isNewValidRequestConfig) {
      showConfirmModal()
    }
  }, [prevRemoveModalConfig, removePreferenceModalConfig, showConfirmModal])

  const dataTableSettings = React.useMemo(
    () => ({
      actions: [
        {
          alwaysShow: () => true,
          callback: (unit: UnitWithHousekeeperPreferences) =>
            setActiveUnit(unit),
          condition: (unit: UnitWithHousekeeperPreferences) =>
            unit !== activeUnit,
          title: t(Slugs.edit),
        },
        {
          alwaysShow: () => true,
          callback: () => setActiveUnit({} as UnitWithHousekeeperPreferences),
          condition: (unit: UnitWithHousekeeperPreferences) =>
            unit === activeUnit,
          title: (
            <CancelEditIcon
              centerItems={true}
              dataTestId={UnitPreferencesPageTestIds.cancelEdit}
              icon={IconName.x}
              size={22}
            />
          ),
        },
      ],
      getItemKey: (unit: UnitWithHousekeeperPreferences) => unit.id,
      headerClasses: new Array(4).fill(tableHeader),
      headerValues: [
        <div key={0}>{t(Slugs.unit)}</div>,
        <SvgIcon
          key={1}
          centerItems={true}
          icon={IconName.star}
          text={t(getSlugForUnitPreferenceKind(UnitPreferenceKind.mandatory))}
        />,
        <SvgIcon
          key={2}
          icon={IconName.thumbsUp}
          text={t(getSlugForUnitPreferenceKind(UnitPreferenceKind.suggested))}
        />,
        <SvgIcon
          key={3}
          centerItems={true}
          icon={IconName.slash}
          text={t(getSlugForUnitPreferenceKind(UnitPreferenceKind.excluded))}
        />,
      ],
      rowValueGetters: [
        (unit: Unit) => (
          <>
            <div>{unit.unitCode}</div>
            <UnitAddress>{unitFullAddressString(unit)}</UnitAddress>
          </>
        ),
        (unit: UnitWithHousekeeperPreferences) => (
          <UnitPreferenceCell
            alias={UnitPreferenceKind.mandatory}
            editing={unit === activeUnit}
            openAddPreferenceModal={openAddPreferenceModal}
            openRemovePreferenceModal={openRemovePreferenceModal}
            unit={unit}
          />
        ),
        (unit: UnitWithHousekeeperPreferences) => (
          <UnitPreferenceCell
            alias={UnitPreferenceKind.suggested}
            editing={unit === activeUnit}
            openAddPreferenceModal={openAddPreferenceModal}
            openRemovePreferenceModal={openRemovePreferenceModal}
            unit={unit}
          />
        ),
        (unit: UnitWithHousekeeperPreferences) => (
          <UnitPreferenceCell
            alias={UnitPreferenceKind.excluded}
            editing={unit === activeUnit}
            openAddPreferenceModal={openAddPreferenceModal}
            openRemovePreferenceModal={openRemovePreferenceModal}
            unit={unit}
          />
        ),
      ],
    }),
    [activeUnit, openAddPreferenceModal, openRemovePreferenceModal, t],
  )

  const isMissingPayRates =
    !activeUnit.rateContractorDeepClean || !activeUnit.rateContractorPostStay

  return (
    <SentryErrorBoundary boundary={'unit-preferences-page'}>
      <ContentWrapper>
        <HeaderWrapper>
          <HeaderText>{startCase(t(Slugs.unitPreferences))}</HeaderText>
        </HeaderWrapper>
        <StyledDataTable
          isLoading={isLoading}
          items={unitsWithPreferences}
          loadingItemIds={loadingUnitIds}
          {...dataTableSettings}
        />

        <AddPreferenceModal
          afterClose={() => setAddPreferenceModalConfig(null)}
          config={addPreferenceModalConfig}
          housekeepers={availableHks}
          isMissingPayRates={isMissingPayRates}
          onConfirm={handleOnConfirm}
        />
      </ContentWrapper>
    </SentryErrorBoundary>
  )
}

export default React.memo(UnitPreferencesPage)
